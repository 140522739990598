import request from '@/utils/request'
/**
 *  项目模块 api
 */
// 项目配置平台费用信息
export const projectGetConfigInfo = () =>
  request({
    url: '/project/getConfigInfo',
    method: 'get'
  })
// 项目信息列表
export const projectList = params =>
  request({
    url: '/project/list',
    method: 'get',
    params
  })
//
export const getDescList = params =>
  request({
    url: `/project/${params.projectId}/member/descList`,
    method: 'get',
    params
  })
export const changeProjectInfo = params =>
  request({
    url: `/project/update`,
    method: 'put',
    data: JSON.stringify(params)
  })
export const checkWord = params =>
  request({
    url: '/project/document/check',
    method: 'post',
    data: params
  })
export const changeOrder = params =>
  request({
    url: '/project/plan/order',
    method: 'post',
    data: params
  })
export const getProjectAuth = params =>
  request({
    url: `/project/${params.projectId}/auth`,
    method: 'get',
    params
  })
// /project/{projectId}/process/{id}
export const delProcess = params =>
  request({
    url: `/project/${params.projectId}/process/${params.id}`,
    method: 'delete',
    params
  })
export const getTendererList = params =>
  request({
    url: `/project/bid/tenderer/${params.tendererId}/member/list`,
    method: 'get',
    params
  })
export const delTendererList = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/member/${params.id}`,
    method: 'delete',
    params
  })
//
export const addMember = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/member`,
    method: 'put',
    data: JSON.stringify(params)
  })
export const addProjectMember = params =>
  request({
    url: `/project/${params.projectId}/member`,
    method: 'put',
    data: JSON.stringify(params)
  })
// 模块进度

export const getModuleList = params =>
  request({
    url: `/project/process/module/list`,
    method: 'get',
    params
  })
export const addMemberProcess = params =>
  request({
    url: `/project/process/member`,
    method: 'put',
    data: JSON.stringify(params)
  })
export const addProjectProcess = params =>
  request({
    url: `/project/process`,
    method: 'put',
    data: JSON.stringify(params)
  })
export const addModuleList = params =>
  request({
    url: `/project/process/module`,
    method: 'put',
    data: JSON.stringify(params)
  })
export const updateModuleList = params => {
  return request({
    url: `/project/process/module`,
    method: 'post',
    data: params
  })
}

// /project/bid/{projectId}/tenderer/win/list projectId

export const getList = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/win/list?types=1&types=2`
  })

export const delMemberProgress = params =>
  request({
    url: `/project/${params.projectId}/process/member/${params.id}`,
    method: 'delete'
  })
// 模块进度
export const delProjectMember = params =>
  request({
    url: `/project/${params.projectId}/member/${params.id}`,
    method: 'delete'
  })

export const updateModuleOrder = params =>
  request({
    url: `/project/${params.projectId}/process/module/order`,
    method: 'post',
    data: params.list
  })
// 模块删除
export const delModel = params =>
  request({
    url: `/project/${params.projectId}/process/module/${params.id}`,
    method: 'delete',
    params
  })
// 热门项目
export const getHotProject = params =>
  request({
    url: '/project/hot/list',
    method: 'get',
    params
  })
export const getProjectStatus = params =>
  request({
    url: '/project/status/list',
    method: 'get',
    params
  })
// 委托环节
export const projectEntrustPart = params =>
  request({
    url: '/projectEntrustPart/list',
    method: 'get',
    params
  })

// 发布项目
export const publishProject = params =>
  request({
    url: '/project/publish',
    method: 'post',
    data: params
  })

// 获取项目信息
export const getDescribeInfoByProjectId = params =>
  request({
    url: `/project/${params.projectId}/detail`,
    method: 'get'
  })
//
// 申请提供样稿
export const applyTenderer = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tendererId}/draft/apply`,
    method: 'post'
  })
// /project/bid/{projectId}/tenderer/{tendererId}/draft/invited
export const invited = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tendererId}/draft/invited`,
    method: 'post'
  })
// /win/bid/{projectId}/tenderer/{tendererId}/win
export const win = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tendererId}/win`,
    method: 'post'
  })

export const tenderRemove = params =>
  request({
    url: `/project/bid/tender/remove/${params.applyId}`,
    method: 'delete'
  })
export const tenderRequestRemove = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tenderId}/remove`,
    method: 'delete'
  })
// 删除样稿
//
export const delDraft = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/draft/${params.draftId}/remove`,
    method: 'delete'
  })
export const remove = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tendererId}/remove`,
    method: 'delete'
  })
// /project/bid/{projectId}/tenderer/{tendererId}/draft/approve
export const approveTenderer = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tendererId}/draft/approve`,
    method: 'post'
  })
export const provideTenderer = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tendererId}/draft/provide`,
    method: 'post'
  })
// 获取当前权限
export const getCurrentUserAuthByProjectId = params =>
  request({
    url: `/project/${params.projectId}/auth`,
    method: 'get'
  })
// 项目启动或停止
export const updateStatus = params =>
  request({
    url: `/project/${params.projectId}/status/${params.status ? 'start' : 'stop'}`,
    method: 'post'
  })
export const setProject = params =>
  request({
    url: '/project/config',
    method: 'post',
    data: params
  })
// 获取项目状态 /projectStatus/list
export const getProjectStatusList = params =>
  request({
    url: '/project/status/list',
    method: 'get',
    params
  })
// 获取项目文档列表
export const getWordList = params =>
  request({
    url: `/project/${params.projectId}/document/list`,
    method: 'get'
  })
// 删除项目文档
export const delWordList = params =>
  request({
    url: `/project/document/remove/${params.id}`,
    method: 'DELETE'
  })
// 上传文档
export const updateWord = params =>
  request({
    url: `/project/document/upload`,
    method: 'post',
    data: params
  })

// 项目竞标信息
export const getProComInfo = params =>
  request({
    url: `/project/bid/${params.projectId}/detail`,
    method: 'get'
  })

export const setProjectConfig = params =>
  request({
    url: `/project/bid/config`,
    method: 'post',
    data: params
  })

export const setPlatformQuotation = params =>
  request({
    url: `/project/bid/platformQuotation/config`,
    method: 'post',
    data: params
  })
// 查看全部投标申请
export const getBidTenders = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/list`,
    method: 'get'
  })

export const setProjectStatus = params =>
  request({
    url: `/project/bid/${params.projectId}/status/${params.status ? 'start' : 'stop'}`,
    method: 'post'
  })

// 删除项目案例
export const delWord = params =>
  request({
    url: `/project/${params.projectId}/case/${params.id}`,
    method: 'get'
  })
//
// 删除平台估价
export const delPrice = params =>
  request({
    url: `/project/bid/${params.projectId}/platformQuotation/remove/${params.quotationId}`,
    method: 'DELETE'
  })
// /project/bid/{projectId}/platformQuotation/list
// 查看平台详细估价

export const checkPlatformQuotation = params =>
  request({
    url: `/project/bid/${params.projectId}/platformQuotation/list`,
    method: 'get'
  })
// /project/bid/tender/config
// 设置投标要求
export const setTenderConfig = params =>
  request({
    url: `/project/bid/tender/config`,
    method: 'post',
    data: params
  })
export const caseSort = params =>
  request({
    url: `/project/${params.projectId}/case/order`,
    method: 'post',
    data: params.ids
  })

//
export const checkTenderList = params =>
  request({
    url: `/project/bid/${params.projectId}/tender/list`,
    method: 'get'
  })

// 申请投标

// /project/bid/tender/apply
export const apply = params =>
  request({
    url: `/project/bid/tender/apply`,
    method: 'put',
    data: JSON.stringify(params)
  })

// /project/bid/tender/remove/{applyId}
export const removeApply = params =>
  request({
    url: `/project/bid/tender/remove/${params.applyId}`,
    method: 'DELETE'
  })

//
export const passApply = params =>
  request({
    url: `/project/bid/${params.projectId}/tender/approve/${params.applyId}`,
    method: 'post'
  })

//
export const getPlatformList = params =>
  request({
    url: `/project/bid/${params.projectId}/platformQuotation/list`,
    method: 'get'
  })

//  视觉样稿列表
export const getUiList = params =>
  request({
    url: `/project/bid/tenderer/${params.tendererId}/draft/ui/list`,
    method: 'get'
  })
//  视觉样稿列表
export const getProductList = params =>
  request({
    url: `/project/bid/tenderer/${params.tendererId}/draft/product/list`,
    method: 'get'
  })
// /process/list
export const getProcessList = params =>
  request({
    url: `/project/${params.projectId}/process/list`,
    method: 'get'
  })
export const getProcessMemberList = params =>
  request({
    url: `/project/${params.projectId}/process/member/list`,
    method: 'get'
  })

export const evaluation = params =>
  request({
    url: `/evaluation/check`,
    method: 'put',
    data: JSON.stringify(params)
  })
export const changePlan = params => {
  return request({
    url: `/project/plan`,
    method: 'post',
    data: params
  })
}
export const getMemberList = params =>
  request({
    url: `/project/${params.projectId}/member/list`,
    method: 'get'
  })

// 查询项目计划
export const getProjectPlanList = params =>
  request({
    url: `/project/${params.projectId}/plan/list`,
    method: 'get'
  })

// 添加项目计划
export const addProjectPlan = params =>
  request({
    url: '/project/plan',
    method: 'put',
    data: JSON.stringify(params)
  })

// 项目计划设为进行中
export const setProjectPlanUnderway = params =>
  request({
    url: `/project/plan/${params.planId}/progress`,
    method: 'post'
  })
export const setProjectPlanNotStarted = params =>
  request({
    url: `/project/plan/${params.planId}/notStarted`,
    method: 'post'
  })
//
export const setProjectPlanComplete = params =>
  request({
    url: `/project/plan/${params.planId}/complete`,
    method: 'post'
  })
// 项目计划撤回
export const setProjectPlanRecall = params =>
  request({
    url: `/project/plan/${params.planId}`,
    method: 'DELETE'
  })

// 评论列表
export const getProjectCommentList = params =>
  request({
    url: `/evaluation/${params.projectId}/project/list`,
    method: 'get'
  })

// 删除评论
export const deleteComment = params =>
  request({
    url: `/evaluation/${params.projectId}/project/${params.id}`,
    method: 'DELETE'
  })
// 添加评论
export const addComment = params =>
  request({
    url: '/evaluation/project',
    method: 'put',
    data: JSON.stringify(params)
  })
export const getWinList = params =>
  request({
    url: `/project/bid/${params.projectId}/tenderer/win/list`,
    method: 'get'
  })
export const tenderUpdate = params => {
  return request({
    url: `/project/bid/${params.projectId}/tender/update`,
    method: 'post',
    data: params
  })
}
export const addProductWord = params => {
  const options = new FormData()
  options.append('file', params.file)
  options.append('tenderId', params.tenderId)
  options.append('projectId', params.projectId)
  return request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tenderId}/draft/product/upload`,
    method: 'post',
    data: options,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const addCase = params =>
  request({
    url: `/project/case`,
    method: 'put',
    data: JSON.stringify(params)
  })

export const addUiWord = params => {
  const options = new FormData()
  options.append('file', params.file)
  options.append('tenderId', params.tenderId)
  options.append('projectId', params.projectId)
  return request({
    url: `/project/bid/${params.projectId}/tenderer/${params.tenderId}/draft/ui/upload`,
    method: 'post',
    data: options,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// /project/{projectId}/case/list
export const getCaseList = params =>
  request({
    url: `/project/${params.projectId}/case/list`,
    method: 'get'
  })
// showCase, hideCase, isShowCase

export const isShowCase = params =>
  request({
    url: `/project/${params.projectId}/case/isShow`,
    method: 'get'
  })
export const hideCase = params =>
  request({
    url: `/project/${params.projectId}/case/hidden`,
    method: 'post'
  })
export const showCase = params =>
  request({
    url: `/project/${params.projectId}/case/show`,
    method: 'post'
  })
export const delCase = params =>
  request({
    url: `/project/${params.projectId}/case/${params.id}`,
    method: 'delete'
  })
export const removeFans = params =>
  request({
    url: `/fans/removeFans`,
    method: 'post',
    data: params
  })
export const followedList = () =>
  request({
    url: `/fans/followedList?businessType=知识视频`,
    method: 'get'
  })
export const getFansList = () =>
  request({
    url: `/fans/list?businessType=知识视频`,
    method: 'get'
  })

export const cancelFollowed = params =>
  request({
    url: `/fans/cancelFollowed`,
    method: 'post',
    data: params
  })

export const getKey = params =>
  request({
    url: `/course/signature/${params}`,
    method: 'post',
    data: params
  })

export const publishCourse = params =>
  request({
    url: `/course/publish`,
    method: 'post',
    data: params
  })

export const priceRankGroup = () =>
  request({
    url: `/course/priceRankGroup`,
    method: 'get'
  })

export const followed = params =>
  request({
    url: `/fans/followed`,
    method: 'post',
    data: params
  })

export const updateAuthorIntroduction = params =>
  request({
    url: `/course/updateAuthorIntroduction`,
    method: 'post',
    data: params
  })

export const coursePassed = params =>
  request({
    url: `/course/passed`,
    method: 'post',
    data: params
  })
export const courseRejected = params =>
  request({
    url: `/course/rejected`,
    method: 'post',
    data: params
  })

export const getPublishInfo = params =>
  request({
    url: `/course/getPublishInfo?courseId=${params.courseId}`,
    method: 'get'
  })

export const removeUploadingVideo = params =>
  request({
    url: `/course/removeUploadingVideo`,
    method: 'post',
    data: params
  })

export const applyRecordList = params =>
  request({
    url: `/course/applyRecordList?courseId=${params.courseId}`,
    method: 'get'
  })

export const publishingCourse = () =>
  request({
    url: `/course/publishingCourse?action=发布`,
    method: 'get'
  })

export const simpleList = params =>
  request({
    url: `/bargainAndTogether/simpleList?productId=${params.productId}&businessType=${params.businessType}`,
    method: 'get'
  })

export const saleRecord = params =>
  request({
    url: `/saleRecord/list`,
    method: 'get',
    params
  })

export const currentLoginUser = params =>
  request({
    url: `/user/currentLoginUser`,
    method: 'get',
    params
  })

export const bargainAndTogether = params =>
  request({
    url: `/bargainAndTogether/list`,
    method: 'get',
    params
  })

export const togetherJoinList = params =>
  request({
    url: `/together/joinList`,
    method: 'get',
    params
  })

export const bargainJoinList = params =>
  request({
    url: `/bargain/joinList`,
    method: 'get',
    params
  })

export const bargainSave = params =>
  request({
    url: `/bargain/save`,
    method: 'post',
    data: params
  })

export const togetherOptionList = params =>
  request({
    url: `/together/optionList`,
    method: 'get',
    params
  })

export const togetherSave = params =>
  request({
    url: `/together/save`,
    method: 'post',
    data: params
  })
// 砍价
export const bargainJoin = params =>
  request({
    url: `/bargain/join`,
    method: 'post',
    data: params
  })
// 拼团
export const togetherJoin = params =>
  request({
    url: `/together/join`,
    method: 'post',
    data: params
  })



<template lang="pug">
  .dialog-content
    el-dialog(:visible.sync="dialogVisible" width="850px" :show-close="showClose" @close="$emit('close')")
      slot
</template>

<script>
export default {
  name: 'Dialog',
  data() {
    return {
      dialogVisible: false
    }
  },
  props: ['show', 'showClose'],
  watch: {
    show(val) {
      this.dialogVisible = val
    },
    dialogVisible(val) {
      if (!val) this.$emit('update:show', false)
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false)
    }
    // handleClose() {}
  }
}
</script>

<style lang="scss">
.dialog-content {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 20px 0 50px 0;
  }
  .el-input {
    width: 440px;
    height: 50px;
  }
  .el-input__inner {
    height: 50px;
    border: 1px solid #dcdfe6;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .el-textarea,
  .el-textarea__inner {
    width: 440px;
    height: 180px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .el-select {
    .el-input__inner {
      border-width: 0 0 1px 0;
      border-color: #dcdfe6;
      border-style: solid;
      border-radius: 2px;
    }
  }
  // .el-input__suffix-inner {
  //   height: 24px;
  // }
  .el-icon-arrow-up:before {
    content: '';
  }
  .el-input__suffix-inner .el-select__caret {
    width: 18px;
    height: 10px;
    background-image: url(./../../assets/down.png);
    background-size: 100% 100%;
    display: block;
    margin-top: 20px;
    transform: rotateZ(0deg);
  }
  .el-select .el-input .el-select__caret.is-reverse {
    transform: rotateZ(180deg);
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #dcdfe6;
  }
  .el-dialog {
    height: auto;
    max-height: 70vh;
    overflow: scroll;
  }
}
</style>

export const statusList = {
  // 项目-整体
  10: '待开始',
  20: '进行中',
  930: '已完成',
  940: '已停止',
  // 销售-谈意向
  110: '待开始',
  120: '进行中',
  130: '已完成',
  140: '已停止',
  // 需求-书写
  210: '新发布',
  220: '需求完善中',
  230: '需求完成',
  240: '需求停止',
  // 竞标
  310: '待竞标',
  320: '竞标中',
  330: '竞标完成',
  340: '竞标停止',
  // 合同-签署
  410: '待签署合同',
  420: '合同签署中',
  430: '合同签署完成',
  440: '合同签署停止',
  // 开发
  510: '待开发',
  520: '开发中',
  530: '已上线',
  540: '开发停止'
}

export const competitiveStatus = {
  // 竞标
  310: '待竞标',
  320: '竞标中',
  330: '竞标完成',
  340: '竞标停止'
}

export const applyStatus = {
  // 竞标
  210: '新发布',
  220: '需求完善中',
  230: '需求完成',
  240: '需求停止'
}
